export const config = {
  propertyTypes: [
    'house',
    'apartment',
    'parking',
    'land',
    'office',
    'commercial',
  ],
  wishes: ['garden', 'parking', 'furnished', 'terrace'],
  conditions: ['poor', 'fair', 'good', 'mint', 'new'],
  numeral_system: 'decimal_comma',
}
