import React from 'react'
import Layout from '../components/layout'
import { UpdatesForm } from '../components/updates-form'

export default (props) => {
  return (
    <Layout
      {...props}
      title="page.get-personal-updates"
      description="cta.tell-us-what-you-are-looking-for.inline"
    >
      <div className="c-row c-row--beta">
        <div className="o-container">
          <UpdatesForm />
        </div>
      </div>
    </Layout>
  )
}
