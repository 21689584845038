import React from 'react'
import { useFormik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { websiteApiClient } from '../utils/api'
import { useAgency } from '../hooks/use-agency'
import HouseIcon from '../assets/img/icons/ico-house.svg'
import ApartmentIcon from '../assets/img/icons/ico-apartment.svg'
import ParkingIcon from '../assets/img/icons/ico-parking.svg'
import LandIcon from '../assets/img/icons/ico-land.svg'
import OfficeIcon from '../assets/img/icons/ico-office.svg'
import CommercialIcon from '../assets/img/icons/ico-commercial.svg'
import Input from '../theming/Input'
import Textarea from '../theming/Textarea'
import H2 from '../theming/H2'
import PanelTitle from '../theming/PanelTittle'
import Loader from './loader'
import { config } from '../config'
import countries from '../utils/countries.json'
import { useLocale } from '../hooks/use-locale'

const TYPES_ICONS = {
  house: <HouseIcon />,
  apartment: <ApartmentIcon />,
  parking: <ParkingIcon />,
  land: <LandIcon />,
  office: <OfficeIcon />,
  commercial: <CommercialIcon />,
}

const UpdatesSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  email: Yup.string()
    .email(<FormattedMessage id="This value is not a valid email address." />)
    .required(<FormattedMessage id="This value should not be blank." />),
  phone: Yup.string().required(
    <FormattedMessage id="This value should not be blank." />
  ),
  min_price: Yup.number().min(0).nullable(),
  max_price: Yup.number()
    .min(0)
    .when('min_price', {
      is: (value) => value >= 0,
      then: Yup.number().moreThan(Yup.ref('min_price'), () => {
        return (
          <FormattedMessage id="Max price should be bigger than mix price" />
        )
      }),
    })
    .nullable(),
  location_preference: Yup.object().shape({
    country: Yup.string().required(
      <FormattedMessage id="This value should not be blank." />
    ),
    postal_codes: Yup.string().required(
      <FormattedMessage id="This value should not be blank." />
    ),
  }),
})

var conditionsImages = {
  new: '/img/svg/house-new.svg',
  fair: '/img/svg/house-fair.svg',
  good: '/img/svg/house-good.svg',
  mint: '/img/svg/house-mint.svg',
  poor: '/img/svg/house-poor.svg',
}

export function UpdatesForm() {
  const locale = useLocale()
  const [success, setSuccess] = React.useState(false)
  const { currencies, settings, agency } = useAgency()
  const [initialCurrency] = currencies
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      condition: 'fair',
      min_price: '',
      max_price: '',
      type: 'house',
      goal: 'for_myself',
      wishes: [],
      location_text: '',
      wishes_text: '',
      min_rooms: 0,
      negotiation: settings.transaction_types === 'let' ? 'let' : 'sale',
      location_preference: {
        country: '',
        postal_codes: '',
      },
      locale,
    },
    onSubmit: async (values) => {
      await websiteApiClient.post('/personal-updates', values)
      setSuccess(true)
    },
    validationSchema: UpdatesSchema,
  })

  const submitted = submitCount > 0
  console.log(errors)
  return (
    <form novalidate onSubmit={handleSubmit}>
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
          <H2 className="section-title">
            <FormattedMessage id="page.get-personal-updates" />
          </H2>
          <p className="section-subtitle">
            <FormattedHTMLMessage id="cta.tell-us-what-you-are-looking-for.inline" />
          </p>
          {success && (
            <div className="c-alert-box c-alert-box--success personalUpdatesFormSuccess">
              <p>
                <FormattedHTMLMessage id="personal-updates.success-message" />
              </p>
            </div>
          )}
        </div>
        {!success && (
          <>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <div className="panel__body panel__body--collapsed">
                {settings.transaction_types === 'let_and_sale' && (
                  <div className="panel-horizontal">
                    <div className="o-grid">
                      <div className="o-grid__item u-1-of-2 c-input-holder">
                        <div className="radio">
                          <label className="c-label">
                            <input
                              type="radio"
                              name="negotiation"
                              value="sale"
                              onChange={handleChange}
                              checked={values.negotiation === 'sale'}
                            />
                            <FormattedMessage id={'personal-updates.buy'} />
                          </label>
                        </div>
                      </div>
                      <div className="o-grid__item u-1-of-2 c-input-holder">
                        <div className="radio">
                          <label className="c-label">
                            <input
                              type="radio"
                              name="negotiation"
                              value="let"
                              onChange={handleChange}
                              checked={values.negotiation === 'let'}
                            />
                            <FormattedMessage id={'personal-updates.rent'} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <span
                      className="c-alert-inline c-alert-inline--error error-negotiation"
                      style={{ display: 'none' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <PanelTitle>
                  <FormattedMessage id="type" />
                </PanelTitle>
                <div className="panel__body">
                  <ul className="c-tile-list c-tile-list--grid c-tile-list--bordered">
                    {config.propertyTypes.map((type) => {
                      return (
                        <li className="c-tile-list__tile">
                          <label className="c-label">
                            <i className="o-icon--big">{TYPES_ICONS[type]}</i>
                            <div className="radio">
                              <input
                                type="radio"
                                name="type"
                                value={type}
                                onChange={handleChange}
                                checked={values.type === type}
                              />
                              <span className="c-tile-list__title">
                                <FormattedMessage
                                  id={`property.types.${type}`}
                                />
                              </span>
                            </div>
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <PanelTitle>
                  <FormattedMessage id={'budget'} />
                </PanelTitle>
                <div className="panel__body">
                  {currencies.length > 1 && (
                    <div className="o-grid c-input-holder">
                      <div className="o-grid__item u-2-of-6">
                        <label
                          className="c-label c-label--prefix c-label--sm"
                          htmlFor="price-currency"
                        >
                          <FormattedMessage id={'currency'} />
                        </label>
                      </div>
                      <div className="o-grid__item u-3-of-6">
                        <select
                          className="c-input-text c-input-text--sm c-input-option currency-select"
                          id="price-currency"
                        >
                          {currencies.map((currency) => (
                            <option value={currency} key={currency}>
                              {currency}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="o-grid c-input-holder">
                    <div className="o-grid__item u-2-of-6">
                      <label
                        className="c-label c-label--prefix c-label--sm"
                        htmlFor="price-max"
                      >
                        <FormattedMessage id={'max'} />
                      </label>
                    </div>
                    <div className="o-grid__item u-3-of-6">
                      <Input
                        type="number"
                        className={classNames(
                          'c-input-text c-input-text--md c-input-text--prefix',
                          {
                            'has-error': errors.max_price && submitted,
                          }
                        )}
                        name="max_price"
                        id="price-max"
                        placeholder="Max."
                        min="0"
                        step="1"
                        value={values.max_price}
                        onChange={handleChange}
                      />
                      {errors.max_price && submitted && (
                        <ErrorMessage message={errors.max_price} />
                      )}
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--md currency-label">
                        {initialCurrency}
                      </span>
                    </div>
                  </div>
                  <div className="o-grid c-input-holder">
                    <div className="o-grid__item u-2-of-6">
                      <label
                        className="c-label c-label--prefix c-label--md"
                        htmlFor="price-min"
                      >
                        <FormattedMessage id={'min'} />
                      </label>
                    </div>
                    <div className="o-grid__item u-3-of-6">
                      <Input
                        type="number"
                        className={classNames(
                          'c-input-text c-input-text--md c-input-text--prefix',
                          {
                            'has-error': errors.min_price && submitted,
                          }
                        )}
                        id="price-min"
                        name="min_price"
                        placeholder="Min."
                        min="0"
                        step="1"
                        value={values.min_price}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--md currency-label">
                        {initialCurrency}
                      </span>
                    </div>
                  </div>
                  {errors.min_price && submitted && (
                    <ErrorMessage message={errors.min_price} />
                  )}
                </div>
              </article>
            </div>

            {['house', 'apartment'].includes(values.type) && (
              <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
                <article className="panel">
                  <PanelTitle>
                    <FormattedMessage id={'min-bedrooms'} />
                  </PanelTitle>
                  <div className="panel__body">
                    <div className="u-text-center c-input-holder">
                      {[0, 1, 2, 3].map((minRoomCount) => {
                        return (
                          <span className="radio radio--inline">
                            <label className="c-label">
                              <input
                                type="radio"
                                value={minRoomCount}
                                name="min_rooms"
                                checked={values.min_rooms == minRoomCount}
                                onChange={handleChange}
                              />
                              {minRoomCount === 3 ? (
                                <FormattedMessage id={'more'} />
                              ) : (
                                minRoomCount
                              )}
                            </label>
                          </span>
                        )
                      })}
                    </div>
                  </div>
                </article>
              </div>
            )}

            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <PanelTitle>
                  <FormattedMessage id={'minimal-condition'} />
                </PanelTitle>
                <div className="panel__body">
                  <div className="center-block-contents conditionHandler">
                    <div className="u-text-center">
                      <div className="conditionTitle">
                        <h2 className={`u-mt-alpha text--${values.condition}`}>
                          <FormattedMessage
                            id={`conditions.${values.condition}`}
                          />
                        </h2>
                        <div className="mb-3">
                          <img
                            src={conditionsImages[values.condition]}
                            style={{ width: 48, height: 48 }}
                          />
                        </div>
                      </div>
                      {config.conditions.map((condition) => {
                        return (
                          <span
                            className={`radio radio--inline radio--${condition}`}
                          >
                            <label className="c-label">
                              <input
                                type="radio"
                                name="condition"
                                value={condition}
                                checked={values.condition === condition}
                                onChange={handleChange}
                              />
                              <FormattedMessage
                                id={`conditions.${condition}`}
                              />
                            </label>
                          </span>
                        )
                      })}
                    </div>
                  </div>
                  <span
                    className="c-alert-inline c-alert-inline--error error-condition"
                    style={{ display: 'none' }}
                  />
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="location" />
                </h2>
                <div className="panel__body">
                  <div className="o-grid o-grid--gutter">
                    <div className="o-grid__item u-2-of-5-bp3 c-input-holder">
                      <label className="c-label" htmlFor="zipCountry">
                        <FormattedMessage id="personal_updates.fields.country.label" />
                      </label>
                      <select
                        className={classNames('c-input-text c-input-text--md', {
                          'has-error':
                            errors.location_preference?.country && submitted,
                        })}
                        id="zipCountry"
                        name="location_preference.country"
                        value={values.location_preference.country}
                        onChange={handleChange}
                      >
                        <option value="" disabled />
                        {Object.entries(countries).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        })}
                      </select>
                      {errors.location_preference?.country && submitted && (
                        <ErrorMessage
                          message={errors.location_preference.country}
                        />
                      )}
                    </div>
                    <div className="o-grid__item u-3-of-5-bp3  c-input-holder">
                      <label className="c-label" htmlFor="postcodes">
                        <FormattedMessage id="personal_updates.fields.postal_codes.label" />
                      </label>
                      <div>
                        <input
                          type="text"
                          className={classNames(
                            'c-input-text c-input-text--md',
                            {
                              'has-error':
                                errors.location_preference?.postal_codes &&
                                submitted,
                            }
                          )}
                          name="location_preference.postal_codes"
                          value={values.location_preference.postal_codes}
                          onChange={handleChange}
                        />
                      </div>
                      <p>
                        <small>
                          <FormattedMessage id="personal_updates.fields.postal_codes.help" />
                        </small>
                      </p>
                      {errors.location_preference?.postal_codes &&
                        submitted && (
                          <ErrorMessage
                            message={errors.location_preference.postal_codes}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <PanelTitle>
                  <FormattedMessage id="wishes.title" />
                </PanelTitle>
                <div className="panel__body">
                  <div className="o-grid">
                    <ul className="o-list u-mb-gamma">
                      {config.wishes.map((wish) => {
                        return (
                          <li key={wish} className="checkbox checkbox--inline">
                            <label className="c-label">
                              <input
                                type="checkbox"
                                name="wishes"
                                value={wish}
                                onChange={handleChange}
                                checked={values.wishes.includes(wish)}
                              />
                              <FormattedMessage id={`wishes.${wish}`} />
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <Textarea
                    className="c-input-text c-input-text--md"
                    id="message"
                    name="wishes_text"
                    rows="2"
                    cols="30"
                    value={values.wishes_text}
                    onChange={handleChange}
                  />
                  <span
                    className="c-alert-inline c-alert-inline--error error-wishes"
                    style={{ display: 'none' }}
                  />
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <article className="panel">
                <PanelTitle>
                  <FormattedMessage id="contact" />
                </PanelTitle>
                <div className="panel__body">
                  <div className="o-grid o-grid--gutter">
                    <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                      <label className="c-label" htmlFor="name">
                        <FormattedMessage id="name" />
                      </label>
                      <Input
                        type="text"
                        className={classNames('c-input-text c-input-text--md', {
                          'has-error': errors.first_name && submitted,
                        })}
                        name="first_name"
                        id="name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && submitted && (
                        <ErrorMessage message={errors.first_name} />
                      )}
                    </div>
                    <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                      <label className="c-label" htmlFor="familyName">
                        <FormattedMessage id="family-name" />
                      </label>
                      <Input
                        type="text"
                        className={classNames('c-input-text c-input-text--md', {
                          'has-error': errors.last_name && submitted,
                        })}
                        name="last_name"
                        id="familyName"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && submitted && (
                        <ErrorMessage message={errors.last_name} />
                      )}
                    </div>
                    <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                      <label className="c-label" htmlFor="email">
                        <FormattedMessage id="email-address" />
                      </label>
                      <FormattedMessage id="placeholders.email-address">
                        {(placeholder) => {
                          return (
                            <Input
                              type="email"
                              className={classNames(
                                'c-input-text c-input-text--md',
                                {
                                  'has-error': errors.email && submitted,
                                }
                              )}
                              name="email"
                              id="email"
                              placeholder={placeholder}
                              value={values.email}
                              onChange={handleChange}
                            />
                          )
                        }}
                      </FormattedMessage>
                      {errors.email && submitted && (
                        <ErrorMessage message={errors.email} />
                      )}
                    </div>
                    <div className="o-grid__item u-1-of-2-bp3 c-input-holder">
                      <label className="c-label" htmlFor="phone">
                        <FormattedMessage id="phone-number" />
                      </label>
                      <FormattedMessage
                        id="placeholders.phone-number"
                        values={{
                          phone: '+32 XXX XXX XXX',
                        }}
                      >
                        {(placeholder) => {
                          return (
                            <Input
                              type="tel"
                              className={classNames(
                                'c-input-text c-input-text--md',
                                {
                                  'has-error': errors.phone && submitted,
                                }
                              )}
                              name="phone"
                              id="phone"
                              placeholder={placeholder}
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )
                        }}
                      </FormattedMessage>
                      {errors.phone && submitted && (
                        <ErrorMessage message={errors.phone} />
                      )}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3 personalUpdatesFormFields">
              <p>
                {isSubmitting ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    className="c-button c-button--md c-button--alpha c-button--block"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="button.get-personal-updates" />
                  </button>
                )}
              </p>
              <p className="u-color-neutral-base">
                <small>
                  <FormattedHTMLMessage
                    id="personal-updates.disclaimer"
                    values={{ name: agency.name }}
                  />
                </small>
              </p>
            </div>
          </>
        )}
      </div>
    </form>
  )
}

const ErrorMessage = ({ message }) => (
  <span className="c-alert-inline c-alert-inline--error"> {message} </span>
)
